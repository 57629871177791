<template>
    <div class="app-massagist-personal-page-messages">
        <div class="messages">
            <div class="contacts">
                <div class="contact">
                    <div class="avatar">
                        <img :src="require(`@/assets/images/1.png`)" alt="">
                    </div>
                    <div class="name">Администратор</div>
                </div>
            </div>
            <div class="texts">
                <div class="texts-field">
                    <span class="message to-massagist">Текст сообщения</span>
                    <span class="message from-massagist" v-for="message, index in messages" :key="index">{{ message.text }}</span>
                </div>
                <div class="text-input">
                    <textarea name="new-message" id="new-message" maxlength="500" class="new-message" v-model="newMessage">                      
                    </textarea>
                    <div class="blank"><AppSendMessageIcon class="icon" @action="sendMessage"/></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppSendMessageIcon from "@/components/icons/app-send-message-icon.vue";
    export default {
        components: {
            AppSendMessageIcon
        },
        data(){
            return {
                messages: [
                    {
                        text: "Текст сообщения"
                    },
                ],
                newMessage: ""
            }
        },
        methods: {
            sendMessage(){
                this.messages.push({ text: this.newMessage });
                this.newMessage = "";
            }
        },
    }
</script>

<style lang="scss" scoped>
    .app-massagist-personal-page-messages{
        min-height: 500px;
        .messages{
            min-height: 400px;
            max-width: 700px;
            border-radius: 0.5em;
            border: 2px solid $accent-dark;
            display: flex;
            justify-content: space-between;
            .contacts{
                min-width: 50px;
                max-width: 250px;
                width: 30%;
                background-color: $accent;
                border-top-left-radius: 0.5em;
                border-bottom-left-radius: 0.5em;
                display: flex;
                flex-direction: column;
                .contact{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 1em;
                    background-color: $accent-dark;
                    &:first-child{
                        border-bottom: 1px solid $white;
                    }
                    .avatar{
                        width: 30px;
                        height: 30px;
                        border-radius: 100%;
                        background-color: $white;
                        box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
                        margin-bottom: 0.5em;
                        overflow: hidden;
                        position: relative;
                        img{
                            max-width: 100%;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                    .name{
                        color: $white;
                        font-weight: 600;
                        font-size: 0.6em;
                        display: block;
                    }
                }
            }
            .texts{
                width: 70%;
                background-color: $white;
                border-top-right-radius: 0.5em;
                border-bottom-right-radius: 0.5em;
                display: flex;
                flex-direction: column;
                .texts-field{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    height: 90%;
                    padding: 1em;
                    .message{
                        padding: 1em;
                        border-radius: 0.5em;
                        background-color: $accent-dark;
                        color: $white;
                        align-self: flex-start;
                        margin: 0.5em 0;
                    }
                    .from-massagist{
                        align-self: flex-end;
                        padding: 1em;
                        border-radius: 0.5em;
                        border: 1px solid $accent-dark;
                        color: $accent-dark;
                        background-color: $white;
                    }
                }
                .text-input{
                    height: 50px;
                    text-align: center;
                    padding-bottom: 5px;
                    position: relative;
                    .icon{
                        cursor: pointer;
                    }
                    .new-message{
                        width: 95%;
                        height: 100%;
                        padding: 0.3em 4em 0 2em;
                        border: 1px solid $accent;
                        border-radius: 0.4em;
                        transition: 0.3s ease;
                        &:focus{
                            outline: none;
                            transform: translateY(-5px);

                        }                        
                        &:focus ~ .blank {
                            transform: translateY(-5px);

                        }                        
                    }
                    .blank{
                        width: 50px;
                        height: 45px;
                        position: absolute;
                        right: 2%;
                        top: 0;
                        background-color: $white;
                        border-top: 1px solid $accent;
                        border-right: 1px solid $accent;
                        border-bottom: 1px solid $accent;
                        border-top-right-radius: 0.4em;
                        border-bottom-right-radius: 0.4em;
                        transition: 0.3s ease;
                        display: flex;
                        align-items: center;
                        }
                }

            }
        }
    }

    @media screen and (min-width: 768px){
        .app-massagist-personal-page-messages{        
            .messages{
                .contacts{
                    .contact{   
                        flex-direction: row;
                        .avatar{
                            margin-right: 1em;
                            margin-bottom: 0;
                        }  
                        .name{
                            font-size: 1em;
                        }
                    }
                }
            }
        }
    }
</style>